<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.senderId+String(index)"
      class="chat"
      :class="{'chat-left': msgGrp.senderId === formattedChatData.contact.id}"
    >
      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="light-primary"
          :src="msgGrp.avatar"
        />
      </div>
      <div class="chat-body">
        <div class="chat-content">
          <small class="chat-time">
            {{ formatDateToMonthShort(msgGrp.time, { hour: 'numeric', minute: 'numeric' }) }}
          </small>
          <p>
            <small class="text-muted">{{ msgGrp.fullName }}</small>
          </p>
          <p>
            {{ msgGrp.message }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { formatDateToMonthShort } from '@core/utils/filter'
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    // profileUserAvatar: {
    // type: String,
    // required: true,
    // },
  },
  setup(props) {
    const formattedChatData = computed(() => {
      const contact = {
        id: props.chatData.contact.id,
        avatar: props.chatData.contact.avatar,
      }

      let chatLog = []
      if (props.chatData.chat) {
        chatLog = props.chatData.chat.chat
      }

      const formattedChatLog = chatLog
      // let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : undefined
      // let msgGroup = {
      //   sender: chatMessageSenderId,
      //   messages: [],
      // }

      // chatLog.forEach((msg, index) => {
      //   if (chatMessageSenderId === msg.senderId) {
      //     msgGroup.messages.push({
      //       msg: msg.message,
      //       time: msg.time,
      //     })
      //   } else {
      //     chatMessageSenderId = msg.senderId
      //     formattedChatLog.push(msgGroup)
      //     msgGroup = {
      //       senderId: msg.senderId,
      //       userFullname: msg.fullName,
      //       avatar: msg.avatar,
      //       messages: [{
      //         msg: msg.message,
      //         time: msg.time,
      //       }],
      //     }
      //   }
      //   if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      // })

      return {
        formattedChatLog,
        contact,
        // profileUserAvatar: props.profileUserAvatar,
      }
    })

    return {
      formattedChatData,
      formatDateToMonthShort,
    }
  },
}
</script>

<style>

</style>
