import axios from '@axios'
import {
  get,
  post,
} from './helpers'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchChatsAndContacts() {
      return new Promise((resolve, reject) => {
        get('/chat/chats-and-contacts')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProfileUser() {
      return new Promise((resolve, reject) => {
        get('/chat/users/profile-user')
          .then(response => resolve(response))
          .catch(error => reject(error))
        // axios
        //   .get('/apps/chat/users/profile-user')
        //   .then(response => resolve(response))
        //   .catch(error => reject(error))
      })
    },
    getChat(ctx, { userId }) {
      return new Promise((resolve, reject) => {
        get(`/chat/chats/${userId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendMessage(ctx, { contactId, message, senderId }) {
      return new Promise((resolve, reject) => {
        post(`/chat/chats/${contactId}`, { message, senderId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
